@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .heading {
    @apply 4xl:text-[5.9rem] 3xl:text-[5.4rem] 2xl:text-[5rem]  md:text-[2.5rem] sm:text-[2rem] text-[1rem];
  }
  .heading0 {
    @apply font-bold lg:text-[65px] md:text-[48px] sm:text-[35px] text-[28px];
  }
  .heading0-light {
    @apply font-bold lg:text-[60px] md:text-[43px] sm:text-[30px] text-[25px];
  }
  .heading1 {
    @apply font-medium 4xl:text-[4rem] 3xl:text-[3.5rem] 2xl:text-[3.3rem]  md:text-[1.6rem] sm:text-[1.48rem] text-[.79rem];
  }
  .heading1-light {
    @apply lg:text-[40px] md:text-[28px] sm:text-[22px] text-[18px];
  }
  .heading2 {
    @apply leading-tight  4xl:text-[3.1rem] 2xl:text-[2.6rem]  lg:text-[28px] md:text-[1.3rem] sm:text-[19px]  text-[.55rem];
  }
  .heading3 {
    @apply lg:text-[30px] md:text-[20px] sm:text-[18px] text-[18px];
  }
  .heading4 {
    @apply lg:text-[24px] md:text-[18px] sm:text-[18px] text-[18px];
  }
  .heading5 {
    @apply lg:text-[22px] md:text-[18px] sm:text-[18px] text-[18px];
  }
  .body1 {
    @apply lg:text-[17px] md:text-[14px] sm:text-[12px] text-[10px];
  }
  .body2 {
    @apply lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px];
  }
  .body3 {
    @apply lg:text-[6.6rem] lg:leading-[7rem] md:text-[14px] sm:text-[13px] text-[12px];
  }

  .h-dynamic-screen {
    height: 100vh; /* fallback for Opera, IE and etc. */
    height: 100dvh;
  }
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../public/assets/fonts/DMSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Medium";
  src: url("../public/assets/fonts/DMSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Regular";
  src: url("../public/assets/fonts/DMSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../public/assets/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../public/assets/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../public/assets/fonts/Montserrat-Regular.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../public/assets/fonts/Montserrat-Light.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.downcarve{
  border-bottom-left-radius: 100% 6%;
  border-bottom-right-radius: 100% 6%;

}

.card-content {

  height: 300px;
}

@media screen and (max-width: 768px) {
  .card-content {
   
    height: 320px;
  }
}


.icon {
  
  fill: #ffffff;
  stroke: #ffffff;
}

.icon svg { 
  --size: 3em;
  
  fill: inherit;
  min-height: var(--size);
  min-width:  var(--size);
}